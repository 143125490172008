import React from "react";
import {Box, Divider, Typography} from "@mui/material";
import {dataMap} from "../assets/data/dataMap";
import {Sezione} from "./Sezione";

export const FormContent = ({step, dati, handleSetDati, dirittoPernottamento, recap}) => {
    return (
        <Box id={'form'}>
            {dataMap[step].sezioni.map((s, i) => <Sezione recap={recap} handleSetDati={handleSetDati} contenuto={s}
                                                          key={i} dati={dati} step={step}
                                                          dirittoPernottamento={dirittoPernottamento}/>)}
        </Box>
    )
}
